import SecureLS from 'secure-ls';
export const secureStorage = new SecureLS({ encodingType: 'aes' });

export const USER_PROFILE_KEYS = Object.freeze({
  userName: 'userName',
  userLastName: 'userLastName',
  userEmail: 'userEmail',
  userPhone: 'userPhone',
  userMessage: 'userMessage'
});

export type UserProfileType = {
  userName?: string;
  userLastName?: string;
  userEmail?: string;
  userPhone?: string;
  userMessage?: string;
};

export const USER_PERSONALIZATION_KEY = 'gaf_persona';
export const USER_PROFILE_OBJECT_KEY = 'userProfile';
export const COMPARE_TRAY_KEY = 'gaf_compare_tray';
export const USER_PROFILE_EXPIRATION_KEY = 'userProfileExpiration';
export const USER_PROFILE_EXPIRATION_TIME = 1000 * 60 * 60 * 24 * 30; // 30 days

export const USER_PROFILE_FIELDS = Array.from(Object.values(USER_PROFILE_KEYS));

export const getUserProfile = () => {
  const userProfile = secureStorage.get(USER_PROFILE_OBJECT_KEY);
  return userProfile ? JSON.parse(userProfile) : {};
};

export const updateUserProfile = (properties: Partial<UserProfileType>) => {
  let newUserProfile: UserProfileType | null = null;
  const userProfile = getUserProfile();

  if (userProfile) {
    newUserProfile = {
      ...userProfile,
      ...properties
    };
  } else {
    newUserProfile = {
      ...properties
    };
  }

  const now = new Date();
  const time = now.getTime() + USER_PROFILE_EXPIRATION_TIME;

  secureStorage.set(USER_PROFILE_EXPIRATION_KEY, time.toString());

  secureStorage.set(USER_PROFILE_OBJECT_KEY, JSON.stringify(newUserProfile));
};

export const COOKIE_NAMES = Object.freeze({
  geolocation: 'geolocation',
  persona: 'gaf_persona'
});

export type GeolocationType = {
  country: string;
  zip: string;
  latitude?: number;
  longitude?: number;
  address?: string;
  city?: string;
  state?: string;
  street?: string;
  secondStreet?: string;
};

export const GEOLOCATION_EXPIRATION_KEY = 'geolocationExpiration';
export const GEOLOCATION_EXPIRATION_TIME = 1000 * 60 * 60 * 24 * 30; // 30 days
