// /**
// * FOCUSABLE ELEMENTS

import { ExtendedWindowType } from '../coveo/engine';
import { Document } from '../react-components/document-listing/response';
import { DataLayerObject } from './global-types';

// */
export const focusableElements = [
  '[contenteditable]',
  '[tabindex]:not([tabindex^="-"])',
  'a[href]',
  'area[href]',
  'button:not([disabled]):not([aria-hidden])',
  'embed',
  'iframe',
  'input:not([disabled]):not([type="hidden"]):not([aria-hidden])',
  'object',
  'select:not([disabled]):not([aria-hidden])',
  'textarea:not([disabled]):not([aria-hidden])'
];

export const createDataLayerString = (
  data: DataLayerObject | DataLayerObject[]
) => {
  return JSON.stringify(data);
};

/* email click */

export const emailClick = (event: Event) => {
  const target = event.target as HTMLAnchorElement;
  const href = target?.href;

  if (href && href.includes('mailto:')) {
    const global = window as ExtendedWindowType;

    global.dataLayer?.push({
      event: 'email_contact_click',
      event_category: 'contact'
    });
  }
};

/* phone click */

export const phoneClick = (event: Event) => {
  const target = event.target as HTMLAnchorElement;
  const href = target?.href;

  if (href && href.includes('tel:')) {
    const global = window as ExtendedWindowType;

    global.dataLayer?.push({
      event: 'phone_contact_click',
      event_category: 'contact'
    });
  }
};

/* detect scroll thresholds */

export const detectScrollThresholds = () => {
  const thresholds = [10, 25, 50, 75, 90]; // Threshold percentages
  const thresholdReached: {
    [key: number]: boolean;
  } = {}; // To keep track of threshold crossing

  window.addEventListener('scroll', () => {
    const windowHeight = window.innerHeight;
    const documentHeight = document.documentElement.scrollHeight;
    const scrollPercentage =
      (window.scrollY / (documentHeight - windowHeight)) * 100;

    thresholds.forEach(threshold => {
      if (scrollPercentage >= threshold && !thresholdReached[threshold]) {
        thresholdReached[threshold] = true;

        const width = window.innerWidth;
        const global = window as ExtendedWindowType;

        global.dataLayer?.push({
          event: 'page_scroll',
          event_attributes: {
            percent_scrolled: threshold,
            browser_width: width
          }
        });
      }
    });
  });
};

/* detect section is visible */

export const detectSectionIsVisible = (
  onVisible: (e: IntersectionObserverEntry) => void,
  selector: string,
  onNotVisible?: () => void
) => {
  const detectVisibleSections = (entries: IntersectionObserverEntry[]) => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        onVisible(entry);
      } else if (onNotVisible) {
        onNotVisible();
      }
    });
  };

  const observer = new IntersectionObserver(detectVisibleSections);
  const sections = document.querySelectorAll(selector);
  sections.forEach(section => {
    observer.observe(section);
  });
};

// one time section visible observer

export const oneTimeVisibleLayerPush = (
  element: HTMLElement,
  data: DataLayerObject
) => {
  const handleIntersection = (
    entries: IntersectionObserverEntry[],
    observer: IntersectionObserver
  ) => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        // Element is now visible
        const global = window as ExtendedWindowType;
        global.dataLayer?.push(data);

        // Unobserve the element to stop further tracking
        observer.unobserve(entry.target);
      }
    });
  };

  // Create an Intersection Observer
  const observer = new IntersectionObserver(handleIntersection);

  // Start observing the element
  observer.observe(element);

  return observer;
};

//remove special characters from string but keep spaces

export const removeSpecialCharacters = (str: string) => {
  return str.replace(/[^a-zA-Z0-9 ]/g, '');
};

//make super script html tags

export const makeSuperScript = (text: string) => {
  if (text) {
    const superScript = ['®', '™', '©', '℠', '¹', '²', '³'];
    const codeTrademark = [
      '&#174;',
      '&#8482;',
      '&#169;',
      '&#8480;',
      '&#185;',
      '&#178;',
      '&#179;'
    ];

    codeTrademark.forEach((code, index) => {
      text = text.replace(new RegExp(code, 'g'), superScript[index]);
    });
    if (superScript.some(sup => text.includes(sup))) {
      const superScriptRegex = /<sup>®|™|©|℠|¹|²|³<\/sup>/g;
      text = text.replace(superScriptRegex, match =>
        match.replace(/<\/?sup>/g, '')
      );
      return text.replace(/®|™|©|℠|¹|²|³/g, match => `<sup>${match}</sup>`);
    }
    return text;
  }
  return '';
};

// get a document following GAF priority
export const getDocumentPriority = (documents: Document[]) => {
  const priorityDocument =
    documents.find(document => document.extension === 'PDF') ||
    documents.find(document => document.extension === 'DOC') ||
    documents.find(document => document.extension === 'DWG') ||
    documents.find(document => document.extension === 'RVT');
  return priorityDocument ? priorityDocument.url : '';
};

export const getExtensionPriority = (documents: Document[]) => {
  const priorityDocument =
    documents.find(document => document.extension === 'PDF') ||
    documents.find(document => document.extension === 'DOC') ||
    documents.find(document => document.extension === 'DWG') ||
    documents.find(document => document.extension === 'RVT');
  return priorityDocument ? priorityDocument.extension : '';
};
//decode HTML entities
export const decodeHtmlEntities = (encodedString: string) => {
  const elem = document.createElement('div');
  elem.innerHTML = encodedString;
  return elem.textContent;
};

export const isInDialog = (modal: HTMLDialogElement, event: MouseEvent) => {
  const rect = modal?.getBoundingClientRect();
  if (rect) {
    return (
      rect.top <= event.clientY &&
      event.clientY <= rect.top + rect.height &&
      rect.left <= event.clientX &&
      event.clientX <= rect.left + rect.width
    );
  }

  return false;
};

// check if any key in an object is empty
export const isAnyKeyEmpty = (obj: object) => {
  for (const key in obj) {
    if (!obj[key as keyof object]) {
      return true;
    }
  }
  return false;
};
