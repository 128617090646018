import Cookies from 'cookies-ts';
import { COOKIE_NAMES, USER_PERSONALIZATION_KEY } from './global-storage';
import modules from '../modules';
import create from '@verndale/core';
import {
  getGeolocationDataFromBrowserAndGoogle,
  getLocationByIpAddress,
  getStoredGeolocationData
} from './geolocation';
import DOMPurify from 'dompurify';
import { ExtendedWindowType } from './global-types';

type AkGeoType = {
  postal: string;
  city: string;
  fips: string;
  region: string;
  country: string;
  dma: string;
  msa: string;
  pmsa: string;
  lat: number;
  long: number;
};

const _window = window as ExtendedWindowType;

export const PERSONALIZE_COMPONENT_LOADED_EVENT = 'personalizeComponentLoaded';

const setPersonaLanguageMap = (persona = true) => {
  const personalizeMapElement = document.querySelector(
    '[data-persona-mapping]'
  ) as HTMLElement;

  let personalizeMap: { [key: string]: string } = {};

  let personalizeLanguage: { [key: string]: [] | '' } = {};

  if (personalizeMapElement) {
    if (personalizeMapElement.dataset.personaMapping) {
      personalizeMap = JSON.parse(
        personalizeMapElement.dataset.personaMapping as string
      );
    }
    if (personalizeMapElement.dataset.languageMapping) {
      personalizeLanguage = JSON.parse(
        personalizeMapElement.dataset.languageMapping as string
      );
    }
  }
  if (persona) {
    return personalizeMap;
  }

  return personalizeLanguage;
};

const checkContractorCommercialRedirect = (persona: string) => {
  const contractorRedirect = (
    document.querySelector('#personalize-contractor') as HTMLDivElement
  )?.dataset.personalize;

  if (contractorRedirect) {
    const event = new CustomEvent('commercialChange', {
      detail: contractorRedirect.split(',').includes(persona)
    });
    window.dispatchEvent(event);
  }
};

const checkTerritoryManagerCommercial = (persona: string) => {
  const territoryRedirect = (
    document.querySelector('#personalize-territory') as HTMLDivElement
  )?.dataset.personalize;

  if (territoryRedirect) {
    const event = new CustomEvent('territoryChange', {
      detail: territoryRedirect.split(',').includes(persona)
    });

    window.dispatchEvent(event);
  }
};

const checkDocumentLandingCommercial = (persona: string) => {
  const documentRedirect = (
    document.querySelector('#personalize-document-landing') as HTMLDivElement
  )?.dataset.personalize;

  if (documentRedirect) {
    const event = new CustomEvent('documentLandingChange', {
      detail: documentRedirect.split(',').includes(persona)
    });
    window.dispatchEvent(event);
  }
};

const languageSelector = (langMapping: {
  [key: string]: string[] | string;
}) => {
  const language: string = window.location.pathname.split('/')[1];

  if (langMapping?.languages?.includes(language)) {
    return language;
  } else {
    return langMapping.default;
  }
};

export const productDetailContent = async (persona: string) => {
  const personalizeMap = setPersonaLanguageMap();
  const personalizeLanguage = setPersonaLanguageMap(false);

  const replaceContentPdp = document.querySelector(
    '#personalize-pdp'
  ) as HTMLDivElement;

  if (replaceContentPdp && personalizeMap?.productDetailFull) {
    const personalizationData = {
      channel: 'WEB',
      currency: 'USD',
      pointOfSale: _window.Engage?.settings?.pointOfSale,
      friendlyId: personalizeMap.productDetailFull as string,
      // optional attributes:
      params: {
        zipCode: persona,
        language: languageSelector(personalizeLanguage)
      }
    };

    const response = await (window as ExtendedWindowType).engage.personalize(
      personalizationData
    );

    const divToInsert = document.querySelector(
      replaceContentPdp.dataset.personalize as string
    );

    if (divToInsert) {
      if (response?.html) {
        divToInsert.innerHTML = DOMPurify.sanitize(response.html);
      } else {
        divToInsert.innerHTML = '';
      }
    }
  }
};

export const stormRules = async (zip: string) => {
  const storm = document.querySelector('#personalize-storm-rule');
  const personalizeMap = setPersonaLanguageMap();
  const personalizeLanguage = setPersonaLanguageMap(false);

  if (personalizeMap?.stormRule && storm) {
    const personalizationData = {
      channel: 'WEB',
      currency: 'USD',
      pointOfSale: _window.Engage?.settings?.pointOfSale,
      friendlyId: personalizeMap?.stormRule as string,
      // optional attributes:
      params: {
        zipCode: zip,
        language: languageSelector(personalizeLanguage)
      }
    };

    const response = await (window as ExtendedWindowType).engage.personalize(
      personalizationData
    );

    if (
      response.stormRuleButton &&
      !window.location.href.includes(response.stormRuleButton.url)
    ) {
      window.location.href = response.stormRuleButton.url as string;
    }
  }
};

export const codeRuleRedirect = async (zip: string) => {
  const personalizeMap = setPersonaLanguageMap();
  const personalizeLanguage = setPersonaLanguageMap(false);

  const codeRuleRedirect = document.querySelector(
    '#personalize-code-rule-redirect'
  );

  if (personalizeMap?.codeRuleRedirect && codeRuleRedirect) {
    const personalizationData = {
      channel: 'WEB',
      currency: 'USD',
      pointOfSale: _window.Engage?.settings?.pointOfSale,
      friendlyId: personalizeMap.codeRuleRedirect as string,
      // optional attributes:
      params: {
        zipCode: zip,
        language: languageSelector(personalizeLanguage)
      }
    };

    const response = await (window as ExtendedWindowType).engage.personalize(
      personalizationData
    );

    if (
      response?.codeRuleButton &&
      !window.location.href.includes(response.codeRuleButton.url)
    ) {
      window.location.href = response.codeRuleButton.url as string;
    }
  }
};

const runFullStackExperience = async () => {
  const personalizeMap = setPersonaLanguageMap();
  const personalizeLanguage = setPersonaLanguageMap(false);

  handleStormCodeRules();
  let redirect = false;
  let existingCookie = new Cookies().get(USER_PERSONALIZATION_KEY);

  const persona = document.querySelector('#persona') as HTMLDivElement;

  if (persona?.dataset?.personalize && !existingCookie) {
    existingCookie = persona?.dataset?.personalize;
    new Cookies().set(USER_PERSONALIZATION_KEY, existingCookie, {
      expires: 60 * 60 * 24 * 30
    });
  }

  const homePersona =
    (document.querySelector('#personalize-home') as HTMLDivElement)?.dataset
      .personalize || '';

  if (existingCookie) {
    if (homePersona === 'default') {
      redirect = true;
    } else {
      checkContractorCommercialRedirect(existingCookie);
      checkTerritoryManagerCommercial(existingCookie);
      checkDocumentLandingCommercial(existingCookie);
      productDetailContent(existingCookie);
    }
  }

  const linksToHome = Array.from(
    document.querySelectorAll<HTMLAnchorElement>('a[href="/"]')
  );

  (personalizeLanguage?.languages as [])?.forEach((lang: string) => {
    const languageLinks = Array.from(
      document.querySelectorAll<HTMLAnchorElement>(`a[href="/${lang}/"]`)
    );
    linksToHome.push(...languageLinks);
  });

  _window.linksToHome = linksToHome;

  if (personalizeMap?.homeUrl) {
    const personalizationData = {
      channel: 'WEB',
      currency: 'USD',
      pointOfSale: _window.Engage?.settings?.pointOfSale,
      friendlyId: personalizeMap.homeUrl as string,
      // optional attributes:
      params: {
        person_type: existingCookie ? existingCookie : ''
      }
    };

    const response = await (window as ExtendedWindowType).engage.personalize(
      personalizationData
    );

    if (response?.url) {
      if (!window.location.href.includes(response.url) && redirect) {
        window.location.href = response.url;
      } else {
        _window.linksToHome?.forEach(element =>
          element.setAttribute('href', response.url as string)
        );
      }
    }
  }
};

const handleStormCodeRules = async () => {
  const data = getStoredGeolocationData();
  if (data) {
    stormRules(data.zip);
    codeRuleRedirect(data.zip);
  } else {
    const google = await getGeolocationDataFromBrowserAndGoogle();
    if (google) {
      stormRules(google.zip);
      codeRuleRedirect(google.zip);
    } else {
      const maxmind = await getLocationByIpAddress();
      if (maxmind) {
        stormRules(maxmind.zip);
        codeRuleRedirect(maxmind.zip);
      }
    }
  }
};

export const personalizeAddressData = async (_body: object) => {
  const personalizeMap = setPersonaLanguageMap();

  if (personalizeMap) {
    const personalizationData = {
      channel: 'WEB',
      currency: 'USD',
      pointOfSale: _window.Engage?.settings?.pointOfSale,
      friendlyId: personalizeMap.addressData as string,
      // optional attributes:
      params: {
        body: _body
      }
    };

    await (window as ExtendedWindowType).engage.personalize(
      personalizationData
    );
  }
};

export const codeRule = async (zip: string) => {
  const personalizeMap = setPersonaLanguageMap();
  const personalizeLanguage = setPersonaLanguageMap(false);

  if (personalizeMap?.codeRule) {
    const personalizationData = {
      channel: 'WEB',
      currency: 'USD',
      pointOfSale: _window.Engage?.settings?.pointOfSale,
      friendlyId: personalizeMap.codeRule as string,
      // optional attributes:
      params: {
        zipCode: zip,
        language: languageSelector(personalizeLanguage)
      }
    };

    const response = await (window as ExtendedWindowType).engage.personalize(
      personalizationData
    );

    return response;
  }
  return;
};

export const dispatchPersonaViewEvent = () => {
  const _window = window as ExtendedWindowType;

  //get page url
  const url = window.location.href;
  //get language from url. Ex: https://www.example.com/en-us/ => en-us
  let language = url.split('/')[3];

  //check if language has language format using refex. Ex: en-us
  if (language && !/^[a-z]{2}-[a-z]{2}$/.test(language)) {
    language = '';
  }

  if (!language) {
    url.includes('.ca') ? (language = 'en-ca') : (language = 'en-us');
  }

  const cookieManager = new Cookies();
  const personaCookie = cookieManager.get(COOKIE_NAMES.persona);
  const akGeo = cookieManager.get('ak_geo') as unknown as AkGeoType;
  let zip = '';

  if (akGeo?.postal) {
    zip = akGeo.postal.split('-')[0];
  }

  const event = {
    channel: 'WEB',
    language,
    currency: 'USD',
    page: url
  };
  const extensionData = {
    state: akGeo?.region || '',
    city: akGeo?.city || '',
    zipCode: zip || '',
    dmaRegionCode: akGeo?.dma || '',
    metroCode: akGeo?.msa || '',
    pmsaCode: akGeo?.pmsa || '',
    country: akGeo?.country || '',
    fips: akGeo?.fips || '',
    personaName: personaCookie || '',
    language
  };
  // Send VIEW event
  if (_window.engage) {
    _window.engage.pageView(event, extensionData);
  }
};

let savedZip = '';

export const shingleDetailContent = async (zip: string, persona?: string) => {
  const personalizeMap = setPersonaLanguageMap();
  const personalizeLanguage = setPersonaLanguageMap(false);
  let _persona = '';

  if (zip) {
    savedZip = zip;
  }

  const savedPersona = new Cookies().get(USER_PERSONALIZATION_KEY);
  if (!persona && savedPersona) {
    _persona = savedPersona;
  } else if (persona) {
    _persona = persona;
  }
  const replaceContentShingle = document.querySelector(
    '#personalize-shingle-code-rule'
  ) as HTMLDivElement;

  if (replaceContentShingle && personalizeMap?.productShingleFull) {
    const personalizationData = {
      channel: 'WEB',
      currency: 'USD',
      pointOfSale: _window.Engage?.settings?.pointOfSale,
      friendlyId: personalizeMap.productShingleFull as string,
      // optional attributes:
      params: {
        zipCode: savedZip,
        persona: _persona,
        language: languageSelector(personalizeLanguage)
      }
    };

    const response = await (window as ExtendedWindowType).engage.personalize(
      personalizationData
    );

    const divToInsert = document.querySelector(
      (replaceContentShingle as HTMLElement).dataset.personalize as string
    );

    if (divToInsert) {
      if (response?.html) {
        divToInsert.innerHTML = DOMPurify.sanitize(response.html);
      } else {
        divToInsert.innerHTML = '';
      }
    }
  }
};

export const handlePersonalizeComponentLoaded = (e: CustomEventInit) => {
  const elementId = e.detail.id;
  const element = document.getElementById(elementId);

  if (element) {
    const elementDataModule = element.dataset.module;
    const childrenWithModule =
      (element.querySelectorAll('[data-module]') as NodeListOf<HTMLElement>) ||
      [];

    const modulesName = [elementDataModule];

    childrenWithModule.forEach(child => {
      const dataModule = child.dataset.module;

      if (dataModule) {
        modulesName.push();
      }
    });

    const modulesToLoad = modules.filter(module =>
      modulesName.includes(module.name)
    );

    create(modulesToLoad);
  }
};

export const handleChangePersona = async (persona: string) => {
  new Cookies().set(USER_PERSONALIZATION_KEY, persona, {
    expires: 60 * 60 * 24 * 30
  });

  const isHome =
    (document.querySelector('#personalize-home') as HTMLDivElement)?.dataset
      .personalize || '';

  const personalizeMap = setPersonaLanguageMap();

  if (personalizeMap?.homeUrl) {
    const personalizationData = {
      channel: 'WEB',
      currency: 'USD',
      pointOfSale: _window.Engage?.settings?.pointOfSale,
      friendlyId: personalizeMap.homeUrl as string,
      // optional attributes:
      params: {
        person_type: persona
      }
    };

    const response = await (window as ExtendedWindowType).engage.personalize(
      personalizationData
    );

    if (response?.url) {
      if (isHome !== '' && !window.location.href.includes(response.url)) {
        window.location.href = response.url;
      } else {
        _window.linksToHome?.forEach(element =>
          element.setAttribute('href', response.url as string)
        );
      }
    }
  }

  checkContractorCommercialRedirect(persona);
  checkTerritoryManagerCommercial(persona);
  checkDocumentLandingCommercial(persona);
  productDetailContent(persona);
};

const initPersonalize = () => {
  const settings = _window.personalizeSettings;
  if (settings) {
    _window.Engage.init(settings).then(e => {
      _window.engage = e;
      dispatchPersonaViewEvent();
      runFullStackExperience();
    });
  } else {
    console.error('Personalize settings not found');
  }
};

export const initPersonalizeActions = () => {
  if (_window.Engage) {
    initPersonalize();
  } else {
    const retryInterval = setInterval(() => {
      if (_window.Engage) {
        initPersonalize();
        clearInterval(retryInterval);
      }
    }, 100);
  }
};
