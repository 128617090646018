import type { Organism } from '@verndale/core';
import scaffoldedModules from './modules-scaffold';
import modulesReact from './modules-react';

const modules: Organism[] = [
  {
    name: 'figure',
    loader: () => import('./modules/figure.js')
  },
  {
    name: 'rating-stars',
    loader: () => import('./modules/rating-stars.js'),
    styles: () => import('../../src/scss/components/_rating-stars.scss')
  },
  {
    name: 'disclaimer',
    styles: () => import('../../src/scss/modules/disclaimer.scss')
  },
  {
    name: 'comparison-card',
    styles: () => import('../../src/scss/modules/comparison-card.scss')
  },
  {
    name: 'comparison-table-row',
    loader: () => import('./modules/comparison-table-row'),
    styles: () => import('../../src/scss/modules/comparison-table-row.scss')
  },
  {
    name: 'product-feature-warranty-row',
    styles: () =>
      import('../../src/scss/modules/product-feature-warranty-row.scss')
  },
  {
    name: 'document-product-card',
    loader: () => import('./modules/document-product-card'),
    styles: () =>
      import('../../src/scss/components/_document-product-card.scss')
  },
  {
    name: 'warranty-modal',
    loader: () => import('./modules/warranty-modal'),
    styles: () => import('../../src/scss/components/_warranty-modal.scss')
  }
];

export default [...modulesReact, ...scaffoldedModules, ...modules];
