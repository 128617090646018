import { Component } from '@verndale/core';
import { debounce } from '../helpers/debounce';

class Rtf extends Component {
  private containers: NodeListOf<HTMLElement>;

  constructor(el: HTMLElement) {
    super(el);
    this.containers = this.el.querySelectorAll('.table');

    this.addContainer();
  }

  setupDefaults() {
    this.dom = {
      tables: this.el.querySelectorAll('table')
    };
  }

  addContainer() {
    (this.dom.tables as NodeListOf<HTMLElement>).forEach(table => {
      if (table.closest('.table')) return;

      const container = document.createElement('div');
      container.classList.add('table');
      table.parentNode?.insertBefore(container, table);
      container.appendChild(table);

      this.isOverflowing(container);
    });

    this.containers = this.el.querySelectorAll('.table');

    window.addEventListener(
      'resize',
      debounce(this.handleResize.bind(this), 250)
    );
  }

  isOverflowing(element: HTMLElement) {
    if (element.scrollWidth > element.clientWidth) {
      element.classList.add('table--overflowing');
    } else {
      element.classList.remove('table--overflowing');
    }
  }

  handleResize() {
    (this.containers as NodeListOf<HTMLElement>).forEach(container => {
      this.isOverflowing(container);
    });
  }
}

export default Rtf;
