/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useMemo, useRef } from 'react';
export type Args = any[];

export const debounce = (callback: any, wait: number) => {
  let timerId: any;
  return (...args: Args) => {
    clearTimeout(timerId);
    timerId = setTimeout(() => {
      callback(...args);
    }, wait);
  };
};

export const useDebounce = (callback: () => void, waitTime: number) => {
  const ref = useRef<() => void>();

  useEffect(() => {
    ref.current = callback;
  }, [callback]);

  const debouncedCallback = useMemo(() => {
    const func = () => {
      ref.current?.();
    };

    return debounce(func, waitTime);
  }, []);

  return debouncedCallback;
};

/* eslint-enable @typescript-eslint/no-explicit-any */
