import 'focus-visible';
import create from '@verndale/core';
import modules from './modules';
import Rtf from './modules/rtf';
import {
  emailClick,
  phoneClick,
  detectScrollThresholds,
  detectSectionIsVisible
} from './helpers/helpers';
import {
  QUIZ_RESULT_NAMES,
  getSummaryResults
} from './react-components/quiz-results/helpers';
import { secureStorage } from './helpers/global-storage';
import {
  handlePersonalizeComponentLoaded,
  initPersonalizeActions,
  PERSONALIZE_COMPONENT_LOADED_EVENT
} from './helpers/personalize';
import { ExtendedWindowType } from './helpers/global-types';

//Backend dispatch this event when the personalize component is loaded. Code is in personalize platform.
window.addEventListener(
  PERSONALIZE_COMPONENT_LOADED_EVENT,
  handlePersonalizeComponentLoaded
);

initPersonalizeActions();

export const initRtf = () => {
  const rtfs = document.querySelectorAll<HTMLElement>('.rtf');

  rtfs?.forEach(rtf => {
    new Rtf(rtf);
  });
};

export const expireStorage = () => {
  const now = new Date();
  const userProfileExpiration = secureStorage.get('userProfileExpiration');
  const geolocationExpiration = secureStorage.get('geolocationExpiration');

  if (userProfileExpiration) {
    const userProfileExpirationDate = new Date(+userProfileExpiration);
    if (now > userProfileExpirationDate) {
      window.localStorage.removeItem('userProfile');
      window.localStorage.removeItem('userProfileExpiration');
    }
  }

  if (geolocationExpiration) {
    const geolocationExpirationDate = new Date(+geolocationExpiration);
    if (now > geolocationExpirationDate) {
      window.localStorage.removeItem('geolocation');
      window.localStorage.removeItem('geolocationExpiration');
    }
  }

  const quizzesResults = getSummaryResults();
  if (quizzesResults) {
    const { contractorResults, costEstimateResults, shingleResults } =
      quizzesResults;

    if (contractorResults?.expirationDate) {
      const contractorResultsExpirationDate = new Date(
        contractorResults.expirationDate
      );
      if (now > contractorResultsExpirationDate) {
        window.localStorage.removeItem(QUIZ_RESULT_NAMES.contractor);
      }
    }

    if (costEstimateResults?.expirationDate) {
      const costEstimateResultsExpirationDate = new Date(
        costEstimateResults.expirationDate
      );
      if (now > costEstimateResultsExpirationDate) {
        window.localStorage.removeItem(QUIZ_RESULT_NAMES.costEstimates);
      }
    }

    if (shingleResults?.expirationDate) {
      const shingleResultsExpirationDate = new Date(
        shingleResults.expirationDate
      );
      if (now > shingleResultsExpirationDate) {
        window.localStorage.removeItem(QUIZ_RESULT_NAMES.shingle);
      }
    }
  }
};

const isStorybook = !!document.getElementById('storybook-root');
const body = document.querySelector('body') as HTMLBodyElement;
const _window = window as ExtendedWindowType;

const pushSection = (e: IntersectionObserverEntry) => {
  const info = (e.target as HTMLElement).dataset.layerVisible;
  if (info) {
    const infoObj = JSON.parse(info);
    const prevData = _window?.dataLayer?.find(
      obj =>
        (obj as { event_category: string; event_action: string })
          .event_category === infoObj.event_category &&
        (obj as { event_category: string; event_action: string })
          .event_action === infoObj.event_action
    );
    if (!prevData) {
      _window.dataLayer.push(infoObj);
    }
  }
};

const setIphoneViewport = () => {
  if (navigator.userAgent.indexOf('iPhone') > -1) {
    document
      .querySelector('[name=viewport]')
      ?.setAttribute(
        'content',
        'width=device-width, initial-scale=1, maximum-scale=1'
      );
  }
};

const pushDataLayer = (e: Event) => {
  const dataset =
    ((e.target as HTMLElement)?.closest('[data-layer]') as HTMLElement)
      ?.dataset || (e.target as HTMLElement)?.dataset;
  if (dataset.layer) {
    const datasetLayers = JSON.parse(dataset.layer as string);
    datasetLayers?.forEach((layer: { event: string }) => {
      if (dataset.oneTime) {
        const prevData = _window?.dataLayer?.find(
          obj => (obj as { event: string }).event === layer.event
        );
        if (!prevData) {
          _window.dataLayer.push(layer);
        }
      } else {
        _window.dataLayer.push(layer);
      }
    });
  }

  phoneClick(e);
  emailClick(e);
};

document.addEventListener(
  'DOMContentLoaded',
  async () => {
    if (!body.dataset.modulesLoaded || isStorybook) {
      expireStorage();
      create(modules).then(() => {
        body.dataset.modulesLoaded = 'true';
        setIphoneViewport();
        initRtf();
        window.addEventListener('click', e => pushDataLayer(e));
        detectScrollThresholds();
        detectSectionIsVisible(pushSection, '[data-layer-visible]');
      });
    }
  },
  false
);
