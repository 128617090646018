import { Result } from '@coveo/headless';
import { secureStorage } from '../../helpers/global-storage';

export const QUIZ_ANSWER_NAMES = {
  contractor: 'contractorAnswers',
  costEstimates: 'costEstimateAnswers',
  shingle: 'shingleAnswers'
};

export const QUIZ_RESULT_NAMES = {
  contractor: 'contractorResults',
  costEstimates: 'costEstimateResults',
  shingle: 'shingleResults'
};

export type contractorAnswersType = {
  zipCode: string;
  countryCode: string;
};

export const CONCTRACTOR_FIELD_NAMES = Object.freeze({
  zipCode: 'userZipCode',
  countryCode: 'userCountryCode'
});

export type costEstimateAnswersType = {
  zip: string;
  homeSquareFootage: number;
  numberOfStories: number;
  garageValue: number;
  roofSlopeValue: number;
  roofComplexityValue: number;
};

export const COST_ESTIMATE_FIELD_NAMES = Object.freeze({
  zip: 'userZipCode',
  country: 'userCountryCode',
  homeSquareFootage: 'homeSquareFootage',
  numberOfStories: 'numberOfStories',
  garageValue: 'garageValue',
  roofSlopeValue: 'roofSlopeValue',
  roofComplexityValue: 'roofComplexityValue',
  chosenContractor: 'chosenContractor',
  estimateUpper: 'estimateupper',
  estimateAverage: 'estimateavg',
  estimateLower: 'estimatelower',
  city: 'userCity',
  state: 'userState',
  street: 'userStreet',
  secondStreet: 'userStreet2'
});

export type shingleAnswersType = {
  userZipCode: string;
  shingleFeatures: string[];
  shingleFeatureValues: string[];
};

export const SHINGLE_FIELD_NAMES = Object.freeze({
  zipCode: 'userZipCode',
  shingleFeatures: 'shingleFeatures'
});

export type contractorResultsType = {
  zipCode: string;
  latitude: number;
  longitude: number;
  results: Result[];
  expirationDate: number;
};

export type costEstimateResultsType = {
  lowestCost: number;
  averageCost: number;
  highestCost: number;
  expirationDate: number;
};

type resultWithChildrenType = Result & { childResults?: Result[] };

export type shingleResultsType = {
  results: resultWithChildrenType[];
  expirationDate: number;
};

export const getSummaryAnswers = () => {
  const contractorAnswers = secureStorage.get(QUIZ_ANSWER_NAMES.contractor);
  const costEstimateAnswers = secureStorage.get(
    QUIZ_ANSWER_NAMES.costEstimates
  );
  const shingleAnswers = secureStorage.get(QUIZ_ANSWER_NAMES.shingle);

  return {
    contractorAnswers: contractorAnswers
      ? (JSON.parse(contractorAnswers) as contractorAnswersType)
      : undefined,
    costEstimateAnswers: costEstimateAnswers
      ? (JSON.parse(costEstimateAnswers) as costEstimateAnswersType)
      : undefined,
    shingleAnswers: shingleAnswers
      ? (JSON.parse(shingleAnswers) as shingleAnswersType)
      : undefined
  };
};

export const getSummaryResults = () => {
  const contractorResults = secureStorage.get(QUIZ_RESULT_NAMES.contractor);
  const costEstimateResults = secureStorage.get(
    QUIZ_RESULT_NAMES.costEstimates
  );
  const shingleResults = secureStorage.get(QUIZ_RESULT_NAMES.shingle);

  return {
    contractorResults: contractorResults
      ? (JSON.parse(contractorResults) as contractorResultsType)
      : undefined,
    costEstimateResults: costEstimateResults
      ? (JSON.parse(costEstimateResults) as costEstimateResultsType)
      : undefined,
    shingleResults: shingleResults
      ? (JSON.parse(shingleResults) as shingleResultsType)
      : undefined
  };
};

export const getContractorAnswers = () => {
  let contractorAnswers = secureStorage.get(QUIZ_ANSWER_NAMES.contractor);
  if (!contractorAnswers) {
    contractorAnswers = secureStorage.get(QUIZ_ANSWER_NAMES.contractor);
  }

  return contractorAnswers
    ? (JSON.parse(contractorAnswers) as contractorAnswersType)
    : undefined;
};

export const getCostEstimateAnswers = () => {
  const costEstimateAnswers = secureStorage.get(
    QUIZ_ANSWER_NAMES.costEstimates
  );

  return costEstimateAnswers
    ? (JSON.parse(costEstimateAnswers) as costEstimateAnswersType)
    : undefined;
};

export const getShingleAnswers = () => {
  const shingleAnswers = secureStorage.get(QUIZ_ANSWER_NAMES.shingle);

  return shingleAnswers
    ? (JSON.parse(shingleAnswers) as shingleAnswersType)
    : undefined;
};

export const getContractorResults = () => {
  const results = secureStorage.get(QUIZ_RESULT_NAMES.contractor);
  return results ? (JSON.parse(results) as contractorResultsType) : undefined;
};

export const getCostEstimateResults = () => {
  const results = secureStorage.get(QUIZ_RESULT_NAMES.costEstimates);
  return results ? (JSON.parse(results) as costEstimateResultsType) : undefined;
};

export const getShingleResults = () => {
  const results = secureStorage.get(QUIZ_RESULT_NAMES.shingle);
  return results ? (JSON.parse(results) as shingleResultsType) : undefined;
};

export const calculateCostEstimateResults = (
  homeSquareFootage: number,
  numberOfStories: number,
  garageValue: number,
  roofSlopeValue: number,
  roofComplexityValue: number,
  territoryPricePerSquareInUSD: number,
  edgesPercentage: number
) => {
  const average = (
    (homeSquareFootage / numberOfStories / 100) *
    (garageValue * roofSlopeValue * roofComplexityValue) *
    territoryPricePerSquareInUSD
  ).toFixed(0);

  const fixedAverage = Number(average);
  const fixedLowestCost = Number(
    (fixedAverage - (fixedAverage * edgesPercentage) / 100).toFixed(0)
  );
  const fixedHighestCost = Number(
    (fixedAverage + (fixedAverage * edgesPercentage) / 100).toFixed(0)
  );

  const results = {
    averageCost: fixedAverage,
    lowestCost: fixedLowestCost,
    highestCost: fixedHighestCost,
    expirationDate: new Date().getTime() + 1000 * 60 * 60 * 24 * 30 // 30 days
  };

  secureStorage.set(QUIZ_RESULT_NAMES.costEstimates, JSON.stringify(results));

  return results;
};
