import SecureLS from 'secure-ls';

import {
  COOKIE_NAMES,
  GEOLOCATION_EXPIRATION_KEY,
  GEOLOCATION_EXPIRATION_TIME,
  GeolocationType
} from './global-storage';
import { MaxmindErrorResponse, MaxmindResponse } from './maxmindResponseTypes';
import { ExtendedWindowType } from './global-types';

export const secureStorage = new SecureLS({ encodingType: 'aes' });

const MAPS_API_KEY =
  (document.querySelector('[data-api]') as HTMLElement)?.dataset?.api ||
  'AIzaSyBiAlQV0Vq0CdAR6on522xLjaMF8iroYPU';

//Check if there is a location in local storage or cookies
export const getStoredGeolocationData = () => {
  let locationData: GeolocationType | null = null;

  const fromLocalStorage = secureStorage.get(COOKIE_NAMES.geolocation);

  if (fromLocalStorage) {
    locationData = JSON.parse(fromLocalStorage);
  }

  return locationData;
};

//same as getStoredGeolocationData but returns country and zip only
export const getStoredGeolocationCountryAndZip = () => {
  let locationData: GeolocationType | null = null;

  const fromLocalStorage = secureStorage.get(COOKIE_NAMES.geolocation);

  if (fromLocalStorage) {
    locationData = JSON.parse(fromLocalStorage);
  }
  return locationData;
};

//Get address from geolocation stored in local storage or cookies

export const getAddressFromGeolocation = () => {
  let address: string | null = null;
  const fromLocalStorage = secureStorage.get(COOKIE_NAMES.geolocation);

  if (fromLocalStorage) {
    const geolocationObj = JSON.parse(fromLocalStorage) as GeolocationType;
    if (geolocationObj.address) address = geolocationObj.address;
  }

  return address;
};

//Get the location data from the lat long using google maps api

export const getLocationDataFromLatLong = async (
  latitude: number,
  longitude: number
) => {
  const response = await window.fetch(
    `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${MAPS_API_KEY}`
  );

  const dataLatLong = await response.json();

  const zipFromData = dataLatLong.results[0].address_components.find(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (component: any) => component.types.includes('postal_code')
  );
  const countryFromData = dataLatLong.results[0].address_components.find(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (component: any) => component.types.includes('country')
  );

  if (!zipFromData) {
    return {
      zip: undefined,
      country: undefined
    };
  }

  if (zipFromData.short_name && countryFromData.short_name) {
    return {
      zip: zipFromData.short_name,
      country: countryFromData.short_name
    };
  } else {
    return {
      zip: undefined,
      country: undefined
    };
  }
};

//Get the location data from the address using google maps api

export const getLocationDataFromAddress = async (address: string) => {
  const response = await window.fetch(
    `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${MAPS_API_KEY}`
  );

  const dataLocation = await response.json();
  //return if location is not valid
  if (!dataLocation || dataLocation?.status === 'ZERO_RESULTS') {
    return {
      zip: undefined,
      country: undefined,
      latitude: undefined,
      longitude: undefined,
      city: undefined,
      street: undefined,
      streetNumber: undefined,
      state: undefined,
      secondStreet: undefined
    };
  }

  const zipFromData = dataLocation.results[0].address_components.find(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (component: any) => component.types.includes('postal_code')
  );
  const countryFromData = dataLocation.results[0].address_components.find(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (component: any) => component.types.includes('country')
  );
  const latitude = dataLocation.results[0].geometry.location.lat;
  const longitude = dataLocation.results[0].geometry.location.lng;

  const city = dataLocation.results[0].address_components.find(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (component: any) => component.types.includes('locality')
  );
  const street = dataLocation.results[0].address_components.find(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (component: any) => component.types.includes('route')
  );

  const secondStreet = dataLocation.results[0].address_components.find(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (component: any) => component.types.includes('subpremise')
  );
  const state = dataLocation.results[0].address_components.find(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (component: any) => component.types.includes('administrative_area_level_1')
  );

  const streetNumber = dataLocation.results[0].address_components?.find(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (component: any) => {
      return component.types.includes('street_number');
    }
  );

  if (zipFromData?.short_name && countryFromData?.short_name) {
    return {
      zip: zipFromData?.short_name || '',
      country: countryFromData?.short_name || '',
      latitude,
      longitude,
      city: city?.long_name || '',
      street: street?.long_name || '',
      secondStreet: secondStreet?.long_name || '',
      state: state?.long_name || '',
      streetNumber: streetNumber?.long_name || ''
    };
  } else {
    return {
      zip: undefined,
      country: undefined,
      latitude: undefined,
      longitude: undefined,
      city: undefined,
      street: undefined,
      state: undefined,
      streetNumber: undefined,
      secondStreet: undefined
    };
  }
};

//Get lat long from the browser
export const getBrowserGeolocationData = async () => {
  try {
    const data = await new Promise((resolve, reject) => {
      let timeoutId: NodeJS.Timeout | null = null;

      const successCallback = (position: GeolocationPosition) => {
        if (timeoutId) {
          clearTimeout(timeoutId);
        }
        const { latitude, longitude } = position.coords;
        resolve({ latitude, longitude });
      };

      const errorCallback = (error: GeolocationPositionError) => {
        if (timeoutId) {
          clearTimeout(timeoutId);
        }
        reject(error);
      };

      navigator.geolocation.getCurrentPosition(successCallback, errorCallback);
      timeoutId = setTimeout(() => {
        reject(new Error('Geolocation permission timeout'));
      }, 5000);
    });

    if (data) {
      return data as {
        latitude: number;
        longitude: number;
      };
    } else {
      return null;
    }
  } catch (error) {
    console.error('Error:', error);
    return null;
  }

  return null;
};

//get location data from the browser and google maps api

export const getGeolocationDataFromBrowserAndGoogle = async () => {
  try {
    const browserData = await getBrowserGeolocationData();

    if (browserData) {
      const { latitude, longitude } = browserData as {
        latitude: number;
        longitude: number;
      };

      const locationData = await getLocationDataFromLatLong(
        latitude,
        longitude
      );

      if (locationData?.zip && locationData?.country) {
        const geolocationData: GeolocationType = {
          address: '',
          latitude,
          longitude,
          zip: locationData.zip,
          country: locationData.country
        };

        updateGeolocationDataProperties(geolocationData);

        return geolocationData;
      }
    }
  } catch (error) {
    console.error(error);
  }

  return null;
};

//Get the latitude and longitude from the zip code using google maps api

export const getLatLongFromZip = async (zip: string, country: string) => {
  let countryCode = country;

  if (country.length === 3) {
    countryCode = country.slice(0, -1);
  }

  const response = await window.fetch(
    `https://maps.googleapis.com/maps/api/geocode/json?key=${MAPS_API_KEY}&components=postal_code:${zip}|country:${countryCode}`
  );

  const data = await response.json();

  if (data.status === 'OK') {
    const { lat, lng } = data.results[0].geometry.location;
    const formattedAddress = data.results[0].formatted_address;

    return {
      latitude: lat,
      longitude: lng,
      address: formattedAddress
    };
  }

  window.dispatchEvent(new Event(INVALID_GOOGLE_ZIP_EVENT));
  return {
    latitude: undefined,
    longitude: undefined,
    address: undefined
  };
};

//Get the location from the ip address using maxmind geoip2

export const getLocationByIpAddress = async () => {
  let result = null;

  try {
    const newData = await new Promise((resolve, reject) => {
      const global = window as ExtendedWindowType;

      if (global.geoip2 && global.geoip2.city) {
        global.geoip2.city(
          (data: MaxmindResponse) => {
            const { latitude, longitude } = data.location;
            const zip = data.postal.code;
            const country = data.country.iso_code;

            if (zip && country && latitude && longitude) {
              const locationData: GeolocationType = {
                address: '',
                latitude,
                longitude,
                zip,
                country: country.toLowerCase()
              };

              updateGeolocationDataProperties(locationData);

              resolve(locationData);
            } else {
              reject(`Missing parameters`);
            }
          },
          (error: MaxmindErrorResponse) => {
            reject(`Error Code: ${error.code}, ${error.error}`);
          }
        );
      } else {
        reject(`geoip2.city is not available`);
      }
    });

    result = newData as GeolocationType;
  } catch (error) {
    console.error(error);
  }

  return result;
};

//get postal code and country code from url

export const getZipAndCountryFromUrl = () => {
  const url = new URL(window.location.href);
  const zip =
    url.searchParams.get('postalCode') || url.searchParams.get('PostalCode');
  const country =
    url.searchParams.get('countryCode') || url.searchParams.get('CountryCode');

  if (zip && country) {
    return {
      zip,
      country: country.toLowerCase()
    };
  }

  return null;
};

//get postal code and country code from url and get lat long from google

export const INVALID_GOOGLE_ZIP_EVENT = 'invalid-google-zip';

export const getGeolocationDataFromUrlAndGoogle = async () => {
  const data = getZipAndCountryFromUrl();

  if (data && data.zip && data.country) {
    const { latitude, longitude } = await getLatLongFromZip(
      data.zip,
      data.country
    );

    if (latitude && longitude) {
      const locationData: GeolocationType = {
        latitude,
        longitude,
        zip: data.zip,
        country: data.country
      };

      return locationData;
    } else {
      setTimeout(() => {
        window.dispatchEvent(new Event(INVALID_GOOGLE_ZIP_EVENT));
      }, 500);

      return {
        latitude: undefined,
        longitude: undefined,
        zip: data.zip,
        country: data.country
      };
    }
  }

  return null;
};

export const COUNTRY_VALUES = ['us', 'ca', 'usa', 'can'];

//update a property in the geolocation object

export const updateGeolocationDataProperty = (key: string, value: string) => {
  let newGeolocationData: GeolocationType | null = null;
  const geolocationData = getStoredGeolocationData();

  if (geolocationData) {
    newGeolocationData = {
      ...geolocationData,
      [key]: value
    };
  } else {
    newGeolocationData = {
      country: '',
      zip: '',
      [key]: value
    } as GeolocationType;
  }

  secureStorage.set(
    COOKIE_NAMES.geolocation,
    JSON.stringify(newGeolocationData)
  );
};

//update multiple properties in the geolocation object

export const updateGeolocationDataProperties = (
  properties: Partial<GeolocationType>
) => {
  let newGeolocationData: GeolocationType | null = null;

  newGeolocationData = {
    ...properties
  } as GeolocationType;

  const now = new Date();
  const time = now.getTime() + GEOLOCATION_EXPIRATION_TIME;

  secureStorage.set(GEOLOCATION_EXPIRATION_KEY, time.toString());

  secureStorage.set(
    COOKIE_NAMES.geolocation,
    JSON.stringify(newGeolocationData)
  );
};
